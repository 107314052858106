<template>
  <div class="newPasswd">
    <p class="_title">设置新密码</p>
    <p class="desc">请为您的账户输入新密码.</p>
    <v-form ref="form" v-model="valid" lazy-validation :style="width">
      <div class="d-flex justify-center align-center">
        <div class="label mr-3" style="width: 80px; text-align: end">
          Password:
        </div>
        <v-text-field
          v-model="user.newPasswd"
          :rules="rules.newPasswd"
          type="password"
          required
        />
      </div>
      <v-btn
        style="min-width: 100 !important"
        :disabled="!valid"
        color="success"
        block
        class="mt-4"
        @click="registerAction"
      >
        提交新密码
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      valid: true,
      user: {
        newPasswd: '',
      },
      uid: '',
      key: '',
      rules: {
        newPasswd: [
          (v) => !!v || '请输入密码',
          (v) => v.length >= 6 || '密码不能小于6位',
        ],
      },
    };
  },
  watch: {},

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width:100%';
        case 'sm':
          return 'width:100%';
        case 'md':
          return 'width:40%';
        case 'lg':
          return 'width:40%';
        case 'xl':
          return 'width:40%';
      }
    },
  },
  created() {
    this.uid = this.$route.query.uid;
    this.key = this.$route.query.key;
  },
  methods: {
    registerAction() {
      let data = Object.assign({}, this.user);
      data.newPasswd = this.$md5(data.newPasswd);
      this.$request({
        url: '/user/newPasswd',
        method: 'post',
        data: {
          uid: this.uid,
          key: this.key,
          newPasswd: data.newPasswd,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.$message.tips('修改成功,去登录吧');
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.newPasswd {
  padding: 0 10px;
  padding-bottom: 200px;
  ._title {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 10px;
  }
  .desc {
    margin-left: 10px;
  }
  .v-btn--block {
    min-width: 0px !important;
    width: 300px;
    margin: 0 auto;
  }
}
</style>
